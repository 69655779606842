/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Button, Text, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-1o5r91t --style4 --full --parallax" name={"uvod"} parallax={true} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/394cc6ae422249949c3628bcfee80af0_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/394cc6ae422249949c3628bcfee80af0_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/394cc6ae422249949c3628bcfee80af0_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/394cc6ae422249949c3628bcfee80af0_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/394cc6ae422249949c3628bcfee80af0_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/394cc6ae422249949c3628bcfee80af0_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/394cc6ae422249949c3628bcfee80af0_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/394cc6ae422249949c3628bcfee80af0_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":1300}} columns={"1"}>
            
            <ColumnWrapper className="--shape5 js-anim  --anim2 --anim-s3 --shadow4 --center mt--10 pb--0 pl--0 pr--0 pt--0" anim={"2"} animS={"3"}>
              
              <Title className="title-box fs--102 w--800" style={{"maxWidth":1276}} content={"<span style=\"color: rgb(250, 5, 5);\"><br><br>Keramika od Palíšků&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--hvr3 btn-box--sbtn2 fs--30 mt--30" content={"<br><br><br><br><br><br><br><br><br><br><br><br><br>Naši keramiku můžete znát již téměř 25 let.&nbsp; Vyrábíme ji z hlíny točířské červenice&nbsp;a tak se hodí do myčky i mikrovlnné trouby. Na povrchu je ručně malovaná pod glazurou,&nbsp;která výrobky dostatečně chrání a tak se barvy časem a používáním nemění.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--40" name={"informace"} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--center" style={{"maxWidth":1300}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 --left" anim={"2"} animS={"3"} style={{"maxWidth":480}}>
              
              <Title className="title-box fs--72" content={"<span style=\"font-weight: bold; color: rgb(250, 6, 6);\">Výrobky a dekorace</span>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"font-weight: bold;\">Keramiku, kterou tvoříme je inspirována tím, co nás obklopuje a co máme rádi. Jsou to především náš pes a kočky, květiny z naší zahrady a příroda, která naši malou obec obklopuje. K tomu ještě něco málo ze staré architektury a starých motorových vozidel. \nSortiment představují především hrníčky různých velikostí a další užitková keramika jako třeba konvice, cukřenky, tácky a talíře.......&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s3" anim={"2"} animS={"3"}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19002/c33c7341d4a74d29bbf517cff16e070c_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/19002/c33c7341d4a74d29bbf517cff16e070c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19002/c33c7341d4a74d29bbf517cff16e070c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19002/c33c7341d4a74d29bbf517cff16e070c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19002/c33c7341d4a74d29bbf517cff16e070c_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/19002/c33c7341d4a74d29bbf517cff16e070c_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"informace-2"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--3" anim={"2"} animS={"3"} style={{"maxWidth":"","backgroundColor":"rgba(242,118,8,1)"}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":360}}>
              
              <Title className="title-box fs--36 w--800 ls--0 lh--14" content={"<span style=\"color: rgb(0, 0, 0);\">Prodej keramiky</span>"}>
              </Title>

              <Text className="text-box w--600" content={"<span style=\"color: rgb(208, 2, 27);\">Naše výrobky si můžete zakoupit na jarmarcích, na které jezdíme, ale také po telefonické dohodě u nás v Křenůvkách, kde máme malou prodejní galerii.&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":360}}>
              
              <Title className="title-box fs--36 w--800 lh--14" content={"Zakázková práce"}>
              </Title>

              <Text className="text-box w--600" content={"<span style=\"color: rgb(208, 2, 27);\">Pro naše zákazníky nabízíme i výrobu na zakázku. Termíny jsou do 2 měsíců od objednávky, cena je +50% běžné ceny</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":360}}>
              
              <Title className="title-box fs--36 w--800 lh--14" content={"Keramika od nás"}>
              </Title>

              <Text className="text-box w--600" content={"<span style=\"color: rgb(208, 2, 27);\">Keramiku jsem začala vyrábět od roku 1998 a v roce 2008 se k této krásné práci přidal i můj manžel Zdeněk. Prodáváme ji na různých řemeslných jarmarcích, městských slavnostech ale především na hrnčířských a keramických trzích. <br>&nbsp;Marcela&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1dsqeaa pb--60 pt--60" name={"kontakt"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/fa7aaf556d9c4d6eb7def1cccbd78abd_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/fa7aaf556d9c4d6eb7def1cccbd78abd_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/fa7aaf556d9c4d6eb7def1cccbd78abd_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/fa7aaf556d9c4d6eb7def1cccbd78abd_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/fa7aaf556d9c4d6eb7def1cccbd78abd_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/fa7aaf556d9c4d6eb7def1cccbd78abd_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/fa7aaf556d9c4d6eb7def1cccbd78abd_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/fa7aaf556d9c4d6eb7def1cccbd78abd_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--10 pt--10" anim={"2"} animS={"3"}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"o-nas"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--2 mb--10 mt--10" style={{"maxWidth":1300}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box fs--36" style={{"maxWidth":383}} content={"<span style=\"color: rgb(255, 255, 255);\">Marcela a Zdeněk Palíškovi</span><br>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: rgb(255, 255, 255);\">Tel:&nbsp; &nbsp; &nbsp; &nbsp; Marcela&nbsp; &nbsp; +420 737 391 008\n<br>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Zdenek&nbsp; &nbsp; &nbsp; +420 776 352 383\n<br>E-mail: paliskova.keramika@seznam.cz <br>IČO:&nbsp; &nbsp; &nbsp; &nbsp;658 24 801\n<br>FB:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;facebook.com/zpalisek&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box fs--18 w--600" style={{"maxWidth":396}} content={"&nbsp;<br>"}>
              </Text>

              <Text className="text-box fs--20" content={"<span style=\"color: rgb(243, 6, 6); font-weight: bold;\">Prodej v zimním období<br><br>V zimě, kdy jarmarky nejsou můžete nakoupit přímo u nás v Křenůvkách. <br>A pokud se Vám nehodí zajíždět na střední Moravu, zavolejte a nebo napište co byste si přáli mít a my Vám keramiku pošleme prostřednictvím služby PPL</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}